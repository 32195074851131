/* tslint:disable */
/* eslint-disable */
/* @relayHash a53d268acab676f83a54fe8f4a391908 */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type WEBHOOK_EVENT_NAME = "COMMENT_CREATED" | "COMMENT_LEFT_MODERATION_QUEUE" | "COMMENT_REPLY_CREATED" | "STORY_CREATED" | "%future added value";
export type CreateWebhookEndpointInput = {
    clientMutationId: string;
    url: string;
    all: boolean;
    events: Array<WEBHOOK_EVENT_NAME>;
};
export type CreateWebhookEndpointMutationVariables = {
    input: CreateWebhookEndpointInput;
};
export type CreateWebhookEndpointMutationResponse = {
    readonly createWebhookEndpoint: {
        readonly endpoint: {
            readonly id: string;
        };
        readonly settings: {
            readonly " $fragmentRefs": FragmentRefs<"WebhookEndpointsConfigContainer_settings">;
        };
    };
};
export type CreateWebhookEndpointMutation = {
    readonly response: CreateWebhookEndpointMutationResponse;
    readonly variables: CreateWebhookEndpointMutationVariables;
};



/*
mutation CreateWebhookEndpointMutation(
  $input: CreateWebhookEndpointInput!
) {
  createWebhookEndpoint(input: $input) {
    endpoint {
      id
    }
    settings {
      ...WebhookEndpointsConfigContainer_settings
      id
    }
  }
}

fragment WebhookEndpointRow_webhookEndpoint on WebhookEndpoint {
  id
  enabled
  url
}

fragment WebhookEndpointsConfigContainer_settings on Settings {
  webhooks {
    endpoints {
      ...WebhookEndpointRow_webhookEndpoint
      id
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "input"
        } as any)
    ], v1 = [
        ({
            "kind": "Variable",
            "name": "input",
            "variableName": "input"
        } as any)
    ], v2 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any), v3 = ({
        "alias": null,
        "args": null,
        "concreteType": "WebhookEndpoint",
        "kind": "LinkedField",
        "name": "endpoint",
        "plural": false,
        "selections": [
            (v2 /*: any*/)
        ],
        "storageKey": null
    } as any);
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "CreateWebhookEndpointMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "CreateWebhookEndpointPayload",
                    "kind": "LinkedField",
                    "name": "createWebhookEndpoint",
                    "plural": false,
                    "selections": [
                        (v3 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Settings",
                            "kind": "LinkedField",
                            "name": "settings",
                            "plural": false,
                            "selections": [
                                {
                                    "args": null,
                                    "kind": "FragmentSpread",
                                    "name": "WebhookEndpointsConfigContainer_settings"
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "CreateWebhookEndpointMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "CreateWebhookEndpointPayload",
                    "kind": "LinkedField",
                    "name": "createWebhookEndpoint",
                    "plural": false,
                    "selections": [
                        (v3 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Settings",
                            "kind": "LinkedField",
                            "name": "settings",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "WebhookConfiguration",
                                    "kind": "LinkedField",
                                    "name": "webhooks",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "WebhookEndpoint",
                                            "kind": "LinkedField",
                                            "name": "endpoints",
                                            "plural": true,
                                            "selections": [
                                                (v2 /*: any*/),
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "enabled",
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "url",
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                (v2 /*: any*/)
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "id": "a53d268acab676f83a54fe8f4a391908",
            "metadata": {},
            "name": "CreateWebhookEndpointMutation",
            "operationKind": "mutation",
            "text": null
        }
    } as any;
})();
(node as any).hash = '54ade6ef7ff75671dd21f18f815e4976';
export default node;
