/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type WEBHOOK_EVENT_NAME = "COMMENT_CREATED" | "COMMENT_LEFT_MODERATION_QUEUE" | "COMMENT_REPLY_CREATED" | "STORY_CREATED" | "%future added value";
export type EventsSelectField_settings = {
    readonly webhookEvents: ReadonlyArray<WEBHOOK_EVENT_NAME>;
    readonly " $refType": "EventsSelectField_settings";
};
export type EventsSelectField_settings$data = EventsSelectField_settings;
export type EventsSelectField_settings$key = {
    readonly " $data"?: EventsSelectField_settings$data;
    readonly " $fragmentRefs": FragmentRefs<"EventsSelectField_settings">;
};



const node: ReaderFragment = ({
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "EventsSelectField_settings",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "webhookEvents",
            "storageKey": null
        }
    ],
    "type": "Settings",
    "abstractKey": null
} as any);
(node as any).hash = 'a9b1624f43c715ae8a8ccec00aa8fba7';
export default node;
