/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type MediaContainer_comment = {
    readonly id: string;
    readonly site: {
        readonly id: string;
    };
    readonly revision: {
        readonly media: ({
            readonly __typename: "GiphyMedia";
            readonly url: string;
            readonly title: string | null;
            readonly width: number | null;
            readonly height: number | null;
            readonly still: string;
            readonly video: string;
        } | {
            readonly __typename: "TenorMedia";
            readonly url: string;
            readonly title: string | null;
        } | {
            readonly __typename: "TwitterMedia";
            readonly url: string;
        } | {
            readonly __typename: "YouTubeMedia";
            readonly url: string;
            readonly still: string;
            readonly title: string | null;
        } | {
            readonly __typename: "ExternalMedia";
            readonly url: string;
        } | {
            /*This will never be '%other', but we need some
            value in case none of the concrete values match.*/
            readonly __typename: "%other";
        }) | null;
    } | null;
    readonly " $refType": "MediaContainer_comment";
};
export type MediaContainer_comment$data = MediaContainer_comment;
export type MediaContainer_comment$key = {
    readonly " $data"?: MediaContainer_comment$data;
    readonly " $fragmentRefs": FragmentRefs<"MediaContainer_comment">;
};



const node: ReaderFragment = (function () {
    var v0 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any), v1 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "url",
        "storageKey": null
    } as any), v2 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "title",
        "storageKey": null
    } as any), v3 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "still",
        "storageKey": null
    } as any), v4 = [
        (v1 /*: any*/)
    ];
    return {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "metadata": null,
        "name": "MediaContainer_comment",
        "selections": [
            (v0 /*: any*/),
            {
                "alias": null,
                "args": null,
                "concreteType": "Site",
                "kind": "LinkedField",
                "name": "site",
                "plural": false,
                "selections": [
                    (v0 /*: any*/)
                ],
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "concreteType": "CommentRevision",
                "kind": "LinkedField",
                "name": "revision",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "media",
                        "plural": false,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "__typename",
                                "storageKey": null
                            },
                            {
                                "kind": "InlineFragment",
                                "selections": [
                                    (v1 /*: any*/),
                                    (v2 /*: any*/),
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "width",
                                        "storageKey": null
                                    },
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "height",
                                        "storageKey": null
                                    },
                                    (v3 /*: any*/),
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "video",
                                        "storageKey": null
                                    }
                                ],
                                "type": "GiphyMedia",
                                "abstractKey": null
                            },
                            {
                                "kind": "InlineFragment",
                                "selections": [
                                    (v1 /*: any*/),
                                    (v2 /*: any*/)
                                ],
                                "type": "TenorMedia",
                                "abstractKey": null
                            },
                            {
                                "kind": "InlineFragment",
                                "selections": (v4 /*: any*/),
                                "type": "TwitterMedia",
                                "abstractKey": null
                            },
                            {
                                "kind": "InlineFragment",
                                "selections": [
                                    (v1 /*: any*/),
                                    (v3 /*: any*/),
                                    (v2 /*: any*/)
                                ],
                                "type": "YouTubeMedia",
                                "abstractKey": null
                            },
                            {
                                "kind": "InlineFragment",
                                "selections": (v4 /*: any*/),
                                "type": "ExternalMedia",
                                "abstractKey": null
                            }
                        ],
                        "storageKey": null
                    }
                ],
                "storageKey": null
            }
        ],
        "type": "Comment",
        "abstractKey": null
    } as any;
})();
(node as any).hash = '5d50c33c120035d403e42c24b145e4ba';
export default node;
