/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type FEATURE_FLAG = "ALTERNATE_OLDEST_FIRST_VIEW" | "AVATARS" | "COMMENT_SEEN" | "CONFIGURE_PUBLIC_PROFILE_URL" | "DATA_CACHE" | "DEFAULT_QA_STORY_MODE" | "DISABLE_LIVE_UPDATES" | "DISABLE_WARN_USER_OF_TOXIC_COMMENT" | "DISCUSSIONS" | "EMAIL_META" | "ENABLE_QA" | "ENABLE_RATINGS_AND_REVIEWS" | "EXTERNAL_MEDIA" | "NEW_COMMENT_COUNT" | "READ_MORE_NEW_TAB" | "REDUCED_SECURITY_MODE" | "RTE_SARCASM" | "SECTIONS" | "VIEWER_COUNT" | "Z_KEY" | "%future added value";
export type UserTableContainer_query = {
    readonly viewer: {
        readonly " $fragmentRefs": FragmentRefs<"UserRowContainer_viewer" | "InviteUsersContainer_viewer">;
    } | null;
    readonly settings: {
        readonly multisite: boolean;
        readonly featureFlags: ReadonlyArray<FEATURE_FLAG>;
        readonly " $fragmentRefs": FragmentRefs<"InviteUsersContainer_settings" | "UserRowContainer_settings">;
    };
    readonly users: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly " $fragmentRefs": FragmentRefs<"UserRowContainer_user">;
            };
        }>;
    };
    readonly " $refType": "UserTableContainer_query";
};
export type UserTableContainer_query$data = UserTableContainer_query;
export type UserTableContainer_query$key = {
    readonly " $data"?: UserTableContainer_query$data;
    readonly " $fragmentRefs": FragmentRefs<"UserTableContainer_query">;
};



const node: ReaderFragment = ({
    "argumentDefinitions": [
        {
            "defaultValue": 10,
            "kind": "LocalArgument",
            "name": "count"
        },
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "cursor"
        },
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "roleFilter"
        },
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "searchFilter"
        },
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "statusFilter"
        }
    ],
    "kind": "Fragment",
    "metadata": {
        "connection": [
            {
                "count": "count",
                "cursor": "cursor",
                "direction": "forward",
                "path": [
                    "users"
                ]
            }
        ]
    },
    "name": "UserTableContainer_query",
    "selections": [
        {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": [
                {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "UserRowContainer_viewer"
                },
                {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "InviteUsersContainer_viewer"
                }
            ],
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "concreteType": "Settings",
            "kind": "LinkedField",
            "name": "settings",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "multisite",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "featureFlags",
                    "storageKey": null
                },
                {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "InviteUsersContainer_settings"
                },
                {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "UserRowContainer_settings"
                }
            ],
            "storageKey": null
        },
        {
            "alias": "users",
            "args": [
                {
                    "kind": "Variable",
                    "name": "query",
                    "variableName": "searchFilter"
                },
                {
                    "kind": "Variable",
                    "name": "role",
                    "variableName": "roleFilter"
                },
                {
                    "kind": "Variable",
                    "name": "status",
                    "variableName": "statusFilter"
                }
            ],
            "concreteType": "UsersConnection",
            "kind": "LinkedField",
            "name": "__UserTable_users_connection",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "UserEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "User",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "id",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "__typename",
                                    "storageKey": null
                                },
                                {
                                    "args": null,
                                    "kind": "FragmentSpread",
                                    "name": "UserRowContainer_user"
                                }
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "cursor",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "endCursor",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "hasNextPage",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ],
    "type": "Query",
    "abstractKey": null
} as any);
(node as any).hash = 'f55f6003530d930869017a740d50e305';
export default node;
