/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type CommentRevisionContainer_comment = {
    readonly id: string;
    readonly site: {
        readonly id: string;
    };
    readonly revision: {
        readonly id: string;
    } | null;
    readonly revisionHistory: ReadonlyArray<{
        readonly id: string;
        readonly body: string | null;
        readonly createdAt: string;
        readonly media: ({
            readonly __typename: "GiphyMedia";
            readonly url: string;
            readonly title: string | null;
            readonly width: number | null;
            readonly height: number | null;
            readonly still: string;
            readonly video: string;
        } | {
            readonly __typename: "TenorMedia";
            readonly url: string;
            readonly title: string | null;
        } | {
            readonly __typename: "TwitterMedia";
            readonly url: string;
        } | {
            readonly __typename: "YouTubeMedia";
            readonly url: string;
            readonly still: string;
            readonly title: string | null;
        } | {
            readonly __typename: "ExternalMedia";
            readonly url: string;
        } | {
            /*This will never be '%other', but we need some
            value in case none of the concrete values match.*/
            readonly __typename: "%other";
        }) | null;
    }>;
    readonly " $refType": "CommentRevisionContainer_comment";
};
export type CommentRevisionContainer_comment$data = CommentRevisionContainer_comment;
export type CommentRevisionContainer_comment$key = {
    readonly " $data"?: CommentRevisionContainer_comment$data;
    readonly " $fragmentRefs": FragmentRefs<"CommentRevisionContainer_comment">;
};



const node: ReaderFragment = (function () {
    var v0 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any), v1 = [
        (v0 /*: any*/)
    ], v2 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "url",
        "storageKey": null
    } as any), v3 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "title",
        "storageKey": null
    } as any), v4 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "still",
        "storageKey": null
    } as any), v5 = [
        (v2 /*: any*/)
    ];
    return {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "metadata": null,
        "name": "CommentRevisionContainer_comment",
        "selections": [
            (v0 /*: any*/),
            {
                "alias": null,
                "args": null,
                "concreteType": "Site",
                "kind": "LinkedField",
                "name": "site",
                "plural": false,
                "selections": (v1 /*: any*/),
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "concreteType": "CommentRevision",
                "kind": "LinkedField",
                "name": "revision",
                "plural": false,
                "selections": (v1 /*: any*/),
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "concreteType": "CommentRevision",
                "kind": "LinkedField",
                "name": "revisionHistory",
                "plural": true,
                "selections": [
                    (v0 /*: any*/),
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "body",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "createdAt",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "media",
                        "plural": false,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "__typename",
                                "storageKey": null
                            },
                            {
                                "kind": "InlineFragment",
                                "selections": [
                                    (v2 /*: any*/),
                                    (v3 /*: any*/),
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "width",
                                        "storageKey": null
                                    },
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "height",
                                        "storageKey": null
                                    },
                                    (v4 /*: any*/),
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "video",
                                        "storageKey": null
                                    }
                                ],
                                "type": "GiphyMedia",
                                "abstractKey": null
                            },
                            {
                                "kind": "InlineFragment",
                                "selections": [
                                    (v2 /*: any*/),
                                    (v3 /*: any*/)
                                ],
                                "type": "TenorMedia",
                                "abstractKey": null
                            },
                            {
                                "kind": "InlineFragment",
                                "selections": (v5 /*: any*/),
                                "type": "TwitterMedia",
                                "abstractKey": null
                            },
                            {
                                "kind": "InlineFragment",
                                "selections": [
                                    (v2 /*: any*/),
                                    (v4 /*: any*/),
                                    (v3 /*: any*/)
                                ],
                                "type": "YouTubeMedia",
                                "abstractKey": null
                            },
                            {
                                "kind": "InlineFragment",
                                "selections": (v5 /*: any*/),
                                "type": "ExternalMedia",
                                "abstractKey": null
                            }
                        ],
                        "storageKey": null
                    }
                ],
                "storageKey": null
            }
        ],
        "type": "Comment",
        "abstractKey": null
    } as any;
})();
(node as any).hash = '7c161551cc4a487fecb3ae417ada08fe';
export default node;
