/* tslint:disable */
/* eslint-disable */
/* @relayHash 1bb13b587e46d8bc66be02250331ba7f */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type WEBHOOK_EVENT_NAME = "COMMENT_CREATED" | "COMMENT_LEFT_MODERATION_QUEUE" | "COMMENT_REPLY_CREATED" | "STORY_CREATED" | "%future added value";
export type UpdateWebhookEndpointInput = {
    clientMutationId: string;
    id: string;
    url?: string | null;
    all?: boolean | null;
    events?: Array<WEBHOOK_EVENT_NAME> | null;
};
export type UpdateWebhookEndpointMutationVariables = {
    input: UpdateWebhookEndpointInput;
};
export type UpdateWebhookEndpointMutationResponse = {
    readonly updateWebhookEndpoint: {
        readonly endpoint: {
            readonly " $fragmentRefs": FragmentRefs<"ConfigureWebhookEndpointContainer_webhookEndpoint">;
        };
    };
};
export type UpdateWebhookEndpointMutation = {
    readonly response: UpdateWebhookEndpointMutationResponse;
    readonly variables: UpdateWebhookEndpointMutationVariables;
};



/*
mutation UpdateWebhookEndpointMutation(
  $input: UpdateWebhookEndpointInput!
) {
  updateWebhookEndpoint(input: $input) {
    endpoint {
      ...ConfigureWebhookEndpointContainer_webhookEndpoint
      id
    }
  }
}

fragment ConfigureWebhookEndpointContainer_webhookEndpoint on WebhookEndpoint {
  ...EndpointDangerZone_webhookEndpoint
  ...EndpointDetails_webhookEndpoint
  ...EndpointStatus_webhookEndpoint
}

fragment ConfigureWebhookEndpointForm_webhookEndpoint on WebhookEndpoint {
  id
  url
  events
  all
}

fragment EndpointDangerZone_webhookEndpoint on WebhookEndpoint {
  id
  enabled
}

fragment EndpointDetails_webhookEndpoint on WebhookEndpoint {
  ...ConfigureWebhookEndpointForm_webhookEndpoint
}

fragment EndpointStatus_webhookEndpoint on WebhookEndpoint {
  id
  enabled
  signingSecret {
    secret
    createdAt
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "input"
        } as any)
    ], v1 = [
        ({
            "kind": "Variable",
            "name": "input",
            "variableName": "input"
        } as any)
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "UpdateWebhookEndpointMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "UpdateWebhookEndpointPayload",
                    "kind": "LinkedField",
                    "name": "updateWebhookEndpoint",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "WebhookEndpoint",
                            "kind": "LinkedField",
                            "name": "endpoint",
                            "plural": false,
                            "selections": [
                                {
                                    "args": null,
                                    "kind": "FragmentSpread",
                                    "name": "ConfigureWebhookEndpointContainer_webhookEndpoint"
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "UpdateWebhookEndpointMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "UpdateWebhookEndpointPayload",
                    "kind": "LinkedField",
                    "name": "updateWebhookEndpoint",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "WebhookEndpoint",
                            "kind": "LinkedField",
                            "name": "endpoint",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "id",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "enabled",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "url",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "events",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "all",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "SigningSecret",
                                    "kind": "LinkedField",
                                    "name": "signingSecret",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "secret",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "createdAt",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "id": "1bb13b587e46d8bc66be02250331ba7f",
            "metadata": {},
            "name": "UpdateWebhookEndpointMutation",
            "operationKind": "mutation",
            "text": null
        }
    } as any;
})();
(node as any).hash = '4f8460aa0b5ff0b894937190e1e781d9';
export default node;
